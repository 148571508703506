import { useState } from 'react'

const useModalState = (defaultState = false) => {
	const [state, setState] = useState(defaultState)

	const open = () => setState(true)
	const close = () => setState(false)

	return [state, open, close]
}

export default useModalState
