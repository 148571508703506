import React, { useState } from 'react'
import Page from 'components/Page'
import Button from 'components/Button'
import Section from 'components/Section'
import Search from 'components/Search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faPlus,
	faCircleNotch,
	faSort,
	faSortUp,
	faSortDown,
	faSync,
} from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import useModalState from 'hooks/useModalState'
import useQuery from 'hooks/useQuery'
import useAuth from 'utils/Auth'

import BuildingRow from './BuildingRow'
import AddBuilding from './AddBuilding'

const compareStr = (a, b) => {
	if (!a || !b) return false
	return a.toLowerCase().indexOf(b.toLowerCase()) !== -1
}

const Buildings = () => {
	const auth = useAuth()

	const [filter, setFilter] = useState('')
	const [sort, setSort] = useState('designation')
	const [dir, setDir] = useState('asc')

	const setSortCol = (name) => {
		if (sort === name) {
			if (dir === 'desc') {
				setSort(null)
				setDir('asc')
			} else {
				setDir('desc')
			}
		} else {
			setSort(name)
			setDir('asc')
		}
	}

	const {
		payload: buildings = [],
		loading,
		query,
	} = useQuery({
		endpoint: `/api/buildings`,
	})

	// console.log('buildings', buildings)

	if (buildings && buildings.error) {
		auth.update({
			token: null,
			user: null,
		})
	}

	const filteredBuildings = buildings
		.filter((building) => {
			if (filter === '') return true
			if (compareStr(building.designation, filter)) return true
			if (compareStr(building.consentnumber, filter)) return true

			return false
		})
		.sort((a, b) => {
			let high = dir === 'asc' ? 1 : -1
			let low = dir === 'asc' ? -1 : 1

			if (sort) {
				return a[sort].toLowerCase() > b[sort].toLowerCase() ? high : low
			}

			return 0
		})

	const SortIcon = (field) => (
		<FontAwesomeIcon
			icon={sort === field ? (dir === 'asc' ? faSortUp : faSortDown) : faSort}
			className={`ml-2 ${sort === field ? 'text-blue-500' : 'text-gray-500'}`}
		/>
	)

	const [add, openAdd, closeAdd] = useModalState()

	return (
		<Page>
			<AddBuilding isOpen={add} onClose={closeAdd} refetch={query} />

			<Section
				number={<FontAwesomeIcon icon={faBuilding} size="sm" />}
				title="Buildings"
				description="Manage buildings and reports you currently have access to."
				buttons={
					<>
						<Search
							placeholder="Search buildings..."
							value={filter}
							onChange={setFilter}
							className="mr-4 w-64"
						/>
						<Button theme="primary-ghost" onClick={query} className="mr-4">
							<FontAwesomeIcon
								icon={faSync}
								fixedWidth
								spin={loading}
								className="mr-2"
							/>
							Refresh
						</Button>
						<Button theme="primary-ghost" onClick={openAdd}>
							<FontAwesomeIcon icon={faPlus} className="mr-2" fixedWidth />
							Add Building
						</Button>
					</>
				}
			>
				{loading ? (
					<div className="flex justify-center mt-5">
						<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
					</div>
				) : (
					<table className="table table-fixed">
						<thead>
							<tr className="bg-gray-100">
								<th width="60"></th>
								<th
									className="cursor-pointer"
									onClick={() => setSortCol('designation')}
								>
									Building Name {SortIcon('designation')}
								</th>
								<th
									className="cursor-pointer"
									onClick={() => setSortCol('consentnumber')}
								>
									Number {SortIcon('consentnumber')}
								</th>
								<th
									className="cursor-pointer"
									onClick={() => setSortCol('address1')}
								>
									Physical Address {SortIcon('address1')}
								</th>
								<th width="80"></th>
							</tr>
						</thead>

						<tbody>
							{filteredBuildings.map((building) => (
								<BuildingRow
									key={building.id}
									building={building}
									refetch={query}
								/>
							))}
						</tbody>
					</table>
				)}
			</Section>
		</Page>
	)
}

export default Buildings
