import React, { useState, useRef } from 'react'
import { connect, getIn } from 'formik'
import FormError from './FormError'

export const getClasses = (formik, name) => {
	return [
		'input',
		getIn(formik.errors, name) &&
			getIn(formik.touched, name) &&
			'input-invalid',
	].join(' ')
}

const FormField = ({
	name,
	label = null,
	style = {},
	className = '',
	disabled,
	formik,
	component = 'input',
	...props
}) => {
	const Comp = component
	const ref = useRef()

	const [token] = useState(Math.floor(Math.random() * 999999))

	return (
		<div className={`flex-1 ${className}`} style={style}>
			{label ? (
				<div
					className="label"
					onClick={() => {
						if (ref.current) ref.current.focus()
					}}
				>
					{label}
				</div>
			) : null}
			<div style={{ fontSize: '0px', lineHeight: '0px', userSelect: 'none' }}>
				{token}
			</div>
			<Comp
				{...props}
				ref={ref}
				autoComplete="off"
				className={getClasses(formik, name)}
				value={getIn(formik.values, name) || ''}
				onBlur={() => formik.setFieldTouched(name)}
				disabled={disabled}
				onChange={(e) => {
					formik.setFieldValue(name, e.target.value)
				}}
			/>
			<FormError name={name} />
		</div>
	)
}

export default connect(FormField)
