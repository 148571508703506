import { createClient, createCache } from 'react-fetching-library'
// import createCache from './cache'
import { saasUrl } from './config'
import { Auth } from 'utils/Auth'

export const transformAction = (action) => ({
	...action,
	endpoint: `${saasUrl}${action.endpoint}`,
	headers: {
		Authorization: 'bearer ' + Auth.state.token,
	},
	emitErrorForStatuses: ['404', '401', '400', '500'],
})

const cache = createCache(
	(action) => {
		return action.method === 'GET' || !action.method
	},
	() => false
)

const requestHostInterceptor = () => async (action) => {
	return transformAction(action)
}

const Client = createClient({
	requestInterceptors: [requestHostInterceptor],
	cacheProvider: cache,
})

export default Client
