import { useEffect } from 'react'

export const getPath = function(event) {
	const path = []
	let currentElem = event.target
	while (currentElem) {
		path.push(currentElem)
		currentElem = currentElem.parentElement
	}
	if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document)
	if (path.indexOf(window) === -1) path.push(window)
	return path
}

const useOutsideClick = (ref, cb) => {
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (getPath(e).indexOf(ref.current) === -1) {
				e.stopPropagation()
				cb()
			}
		}

		document.body.addEventListener('mousedown', handleClickOutside)
		return () => document.body.removeEventListener('mousedown', handleClickOutside)
	}, [ref, cb])
}

export default useOutsideClick
