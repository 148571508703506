import { useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ name = 'react-portal', children }) => {
	const portal = useMemo(() => document.createElement('div'), [])

	useEffect(() => {
		portal.className = name

		document.body.appendChild(portal)

		return () => {
			try {
				document.body.removeChild(portal)
			} catch (e) {}
		}
	}, [portal, name])

	return ReactDOM.createPortal(children, portal)
}

export default Portal
