import React, { useState, useEffect } from 'react'
import swal from 'sweetalert'
import Page from 'components/Page'
import Button from 'components/Button'
import Section from 'components/Section'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { swalLoader } from 'utils/helpers'
import client from 'utils/api'
import useQuery from 'hooks/useQuery'
import { faSun } from '@fortawesome/free-regular-svg-icons'

import ServiceLists from './ServiceLists'
import InstallerList from './InstallerList'

const AdminTools = () => {
	const [jump, setJump] = useState('')

	const { payload: setting, query: refetchJump } = useQuery({
		endpoint: '/api/get_setting/id_jump',
	})

	const {
		payload: autocoms = {
			annottype: [],
			laggingtype: [],
			sepertype: [],
			systemtype: [],
			installers: [],
		},
		query: refetchAutocoms,
	} = useQuery({
		endpoint: '/api/get_autocoms',
	})

	useEffect(() => {
		if (setting) {
			setJump(setting.value)
		}
	}, [setting])

	const updateJump = async () => {
		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: '/api/update_setting',
				body: {
					field: 'id_jump',
					value: jump,
				},
			})

			if (error) throw payload

			await refetchJump()

			await swal({
				icon: 'success',
				title: 'Setting saved',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				title: 'Error saving setting',
				text: e ? e.message : undefined,
			})
		}
	}

	return (
		<Page>
			<Section
				number={<FontAwesomeIcon icon={faSun} size="sm" />}
				title="Admin Tools and Settings"
				description="Manage extra data and settings."
			>
				{/* <p className="mb-4 text font-bold">Export data to CSVs</p>
				<div className="flex items-end border-b border-gray-200 pb-8 mb-8">
					<div className="w-64 mr-4">
						<label className="label">From</label>
						<DatePicker />
					</div>

					<div className="w-64 mr-4">
						<label className="label">To</label>
						<DatePicker />
					</div>

					<div>
						<Button theme="primary">
							<FontAwesomeIcon icon={faFileExport} className="mr-2" fixedWidth />
							Export
						</Button>
					</div>
				</div> */}

				<p className="mb-4 text font-bold">Tablet auto-complete lists</p>
				<div className="flex items-center border-b border-gray-200 pb-8 mb-8">
					<ServiceLists autocoms={autocoms} refetch={refetchAutocoms}>
						{(open) => (
							<Button theme="primary" className="mr-4" onClick={open}>
								<FontAwesomeIcon icon={faList} className="mr-2" fixedWidth />
								Manage Service Lists
							</Button>
						)}
					</ServiceLists>

					<InstallerList autocoms={autocoms} refetch={refetchAutocoms}>
						{(open) => (
							<Button theme="primary" onClick={open}>
								<FontAwesomeIcon icon={faList} className="mr-2" fixedWidth />
								Manage Installer List
							</Button>
						)}
					</InstallerList>
				</div>

				<p className="mb-4 text font-bold">Settings</p>
				<div className="flex items-end mb-8">
					<div className="mr-4">
						<label className="label">ID Allocation Block Size</label>
						<input
							type="number"
							className="input w-64"
							value={jump}
							onChange={(e) => setJump(e.target.value)}
						/>
					</div>

					<div>
						<Button theme="primary" onClick={updateJump}>
							<FontAwesomeIcon icon={faCheck} className="mr-2" fixedWidth />
							Save
						</Button>
					</div>

					<div className="mb-2 ml-4 text-orange-500">
						{setting && setting.value !== jump ? (
							<>
								<FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
								Unsaved
							</>
						) : (
							''
						)}
					</div>
				</div>
			</Section>
		</Page>
	)
}

export default AdminTools
