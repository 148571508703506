import React from 'react'
import swal from 'sweetalert'
import Modal, { ModalContent, ModalFooter } from 'components/Modal'
import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import * as yup from 'yup'
import Form from 'components/Form'
import FormField from 'components/FormField'
import client from 'utils/api'
import { swalLoader } from 'utils/helpers'

const validationSchema = yup.object().shape({
	password: yup
		.string()
		.label('New Password')
		.required(),
})

const initialValues = {
	password: '',
}

const ResetPassword = ({ isOpen, onClose, user, refetch }) => {
	const handleSubmit = async (formData) => {
		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: `/api/update_user/${user.id}`,
				body: formData,
			})

			if (error) throw payload

			await refetch()
			onClose()

			await swal({
				icon: 'success',
				title: 'Password updated',
			})
		} catch (e) {
			swal({
				icon: 'error',
				text: e.message,
			})
		}
	}

	return (
		<Modal open={isOpen} onClose={onClose} title={`Reset Password: ${user.username}`}>
			<Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
				<ModalContent>
					<div className="form-group">
						<FormField name="password" label="New Password" type="password" />
					</div>
				</ModalContent>

				<ModalFooter>
					<Button theme="ghost" size="sm" className="mr-4" onClick={onClose} type="button">
						Cancel
					</Button>

					<Button theme="primary" type="submit">
						<FontAwesomeIcon icon={faCheck} className="mr-2" fixedWidth />
						Submit
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}

export default ResetPassword
