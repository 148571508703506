import React from 'react'
import { connect, getIn } from 'formik'
import FormError from './FormError'
import FileInput from './FileInput'

export const getClasses = (formik, name) => {
	return ['input', getIn(formik.errors, name) && getIn(formik.touched, name) && 'input-invalid'].join(' ')
}

const FormField = ({ name, label = null, style = {}, className = '', disabled, formik, multiple, ...props }) => {
	return (
		<div className={`flex-1 ${className}`} style={style}>
			{label ? (
				<label className="label" htmlFor={name}>
					{label}
				</label>
			) : null}
			<FileInput
				{...props}
				name={name}
				id={name}
				className={getClasses(formik, name)}
				value={getIn(formik.values, name) || ''}
				onBlur={() => formik.setFieldTouched(name)}
				disabled={disabled}
				multiple={multiple}
				onChange={(e) => {
					formik.setFieldValue(name, multiple ? [...e.target.files] : e.target.files[0] || null)
				}}
			/>
			<FormError name={name} />
		</div>
	)
}

export default connect(FormField)
