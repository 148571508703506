import React from 'react'
import swal from 'sweetalert'
import Modal, { ModalContent, ModalFooter } from 'components/Modal'
import Button from 'components/Button'
import useModalState from 'hooks/useModalState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import * as yup from 'yup'
import Form from 'components/Form'
import FormField from 'components/FormField'
import client from 'utils/api'
import { swalLoader } from 'utils/helpers'

const validationSchema = yup.object().shape({
	organisationname: yup
		.string()
		.label('Organisation Name')
		.required(),
	organisationtype: yup
		.string()
		.label('Organisation Type')
		.required(),
	contactperson: yup
		.string()
		.label('Contact Person')
		.required(),
	phone: yup
		.string()
		.label('Phone')
		.required(),
	mobile: yup
		.string()
		.label('Mobile')
		.required(),
	email: yup
		.string()
		.email()
		.label('Email Address')
		.required(),
})

const EditMasterUser = ({ user, refetch, children }) => {
	const [state, open, close] = useModalState()

	const initialValues = {
		organisationname: user.organisationname || '',
		organisationtype: user.organisationtype || '',
		contactperson: user.contactperson || '',
		phone: user.phone || '',
		mobile: user.mobile || '',
		email: user.email || '',
	}

	const handleSubmit = async (formData) => {
		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: `/api/update_user/${user.id}`,
				body: { ...user, ...formData },
			})

			if (error) throw payload

			await refetch()
			close()

			await swal({
				icon: 'success',
				title: 'Master Account updated',
			})
		} catch (e) {
			swal({
				icon: 'error',
				text: e.message,
			})
		}
	}

	return (
		<>
			{children(open)}

			<Modal open={state} onClose={close} title="Edit Master Account">
				<Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
					<ModalContent>
						<div className="form-group">
							<FormField name="organisationname" label="Organisation Name" />
						</div>
						<div className="form-group">
							<FormField name="organisationtype" label="Organisation Type" />
						</div>
						<div className="form-group">
							<FormField name="contactperson" label="Contact Person" />
						</div>
						<div className="form-group">
							<FormField name="phone" label="Phone" />
						</div>
						<div className="form-group">
							<FormField name="mobile" label="Mobile" />
						</div>
						<div className="form-group">
							<FormField name="email" label="Email Address" />
						</div>
					</ModalContent>

					<ModalFooter>
						<Button theme="ghost" size="sm" className="mr-4" onClick={close} type="button">
							Cancel
						</Button>

						<Button theme="primary" type="submit">
							<FontAwesomeIcon icon={faCheck} className="mr-2" fixedWidth />
							Submit
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</>
	)
}

export default EditMasterUser
