import React from 'react'

const TableInfo = ({ page, pageSize, itemCount, totalCount }) => {
	return (
		<div style={{ marginLeft: '0.7em', marginRight: '0.7em', flex: 1 }}>
			Showing <b>{pageSize * (page - 1) + 1}</b> - <b>{pageSize * (page - 1) + itemCount}</b> out of{' '}
			<b>{totalCount}</b>
		</div>
	)
}

export default TableInfo
