import React from 'react'
import Signpost from './Signpost'

const Section = ({ number, title, description, buttons, children, className = '', ...props }) => (
	<div className={`rounded-lg bg-white p-4 shadow-lg ${className}`} {...props}>
		{(title || number || description) && (
			<div className="flex justify-between items-start">
				<Signpost number={number} title={title} description={description} className="m-6" />
				<div className="flex items-center p-6">{buttons}</div>
			</div>
		)}

		<div className="p-6">{children}</div>
	</div>
)

export default Section
