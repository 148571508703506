import React, { useState, useEffect } from 'react'
import swal from 'sweetalert'
import useModalState from 'hooks/useModalState'
import Button from 'components/Button'
import Modal, { ModalContent, ModalFooter } from 'components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import client from 'utils/api'
import { swalLoader } from 'utils/helpers'

const InstallerList = ({ autocoms, refetch, children }) => {
	const [state, open, close] = useModalState()

	const [installers, setInstallers] = useState([])

	useEffect(() => {
		if (autocoms) {
			setInstallers(autocoms.installers.join('\n'))
		}
	}, [autocoms])

	const handleSubmit = async (e) => {
		e.preventDefault()

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: '/api/update_autocoms',
				body: {
					installers: installers.split('\n').join(','),
				},
			})

			if (error) throw payload

			await refetch()
			close()

			await swal({
				icon: 'success',
				title: 'Lists updated',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				title: 'Error updating lists',
				text: e ? e.message : '',
			})
		}
	}

	return (
		<>
			{children(open)}

			<Modal open={state} onClose={close} title="Manage Installer List">
				<form onSubmit={handleSubmit}>
					<ModalContent>
						<div className="text-sm font-bold text-gray-700 mb-2">Installers</div>
						<textarea
							className="w-full resize-none input"
							rows="20"
							value={installers}
							onChange={(e) => setInstallers(e.target.value)}
						/>
					</ModalContent>

					<ModalFooter>
						<Button theme="ghost" size="sm" className="mr-4" onClick={close} type="button">
							Cancel
						</Button>

						<Button theme="primary" type="submit">
							<FontAwesomeIcon icon={faCheck} className="mr-2" fixedWidth />
							Submit
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		</>
	)
}

export default InstallerList
