import React, { useState } from 'react'
import swal from 'sweetalert'
import Modal, { ModalContent } from 'components/Modal'
import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFilePdf, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import client from 'utils/api'
import axios from 'axios'
import { saasUrl, baseUrl } from 'utils/api/config'
import { swalLoader } from 'utils/helpers'
import useAuth from 'utils/Auth'
import useModalState from 'hooks/useModalState'
import FileInput from 'components/FileInput'
import { format } from 'date-fns'

const ManagePlans = ({ isOpen, onClose, area, refetch }) => {
	const [file, setFile] = useState(null)
	const auth = useAuth()

	const activatePlan = async (plan) => {
		await swalLoader()

		try {
			let { error, payload } = await client.query({
				method: 'POST',
				endpoint: `/api/update_area/${area.id}`,
				body: {
					...area,
					plan_id: plan.id,
				},
			})

			if (error) throw payload

			await refetch()

			await swal({
				icon: 'success',
				title: 'Set plan as active',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				text: e.message,
			})
		}
	}

	const handleUpload = async (e) => {
		e.preventDefault()

		if (!file) {
			swal({
				text: 'A file is required',
			})
			return
		}

		await swalLoader()

		try {
			const data = new FormData()

			data.append('name', area.name)
			data.append('level', area.level)
			data.append('area', area.area)
			data.append('pdf', file)

			await axios.post(`${saasUrl}/api/create_plan/${area.id}`, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `bearer ${auth.token}`,
				},
			})

			await refetch()
			setFile(null)

			await swal({
				icon: 'success',
				title: 'Plan uploaded',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				text: e.response.data.message,
			})
		}
	}

	return (
		<Modal open={isOpen} onClose={onClose} title={`Manage Plans: ${area.name}`}>
			<ModalContent>
				<table className="table mb-8">
					<thead>
						<tr>
							<th>PDF</th>
							<th>Date Uploaded</th>
							<th width="120">Is Active</th>
						</tr>
					</thead>
					<tbody>
						{area.plans.map((plan) => (
							<tr key={plan.id}>
								<td>
									<PlanModal plan={plan}>
										{(open) => (
											<Button theme="primary-ghost" size="sm" className="mr-4" onClick={open}>
												<FontAwesomeIcon icon={faFilePdf} fixedWidth />
											</Button>
										)}
									</PlanModal>
									<span>{plan.pdf}</span>
								</td>
								<td>{format(new Date(plan.date), 'yyyy-MM-dd')}</td>
								<td>
									<Button theme="primary-ghost" size="sm" onClick={() => activatePlan(plan)}>
										<FontAwesomeIcon icon={area.plan.id === plan.id ? faCheckCircle : faCircle} />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<form onSubmit={handleUpload}>
					<div className="flex items-center">
						<div className="font-bold text-sm mr-4">Add plan</div>

						<FileInput value={file} onChange={(e) => setFile(e.target.files[0])} className="flex-1 mr-4" />

						<Button theme="primary" type="submit">
							<FontAwesomeIcon icon={faFileUpload} className="mr-2" fixedWidth />
							Upload
						</Button>
					</div>
				</form>
			</ModalContent>
		</Modal>
	)
}

const PlanModal = ({ children, plan }) => {
	const [state, open, close] = useModalState()
	const auth = useAuth()

	return (
		<>
			{children(open)}

			<Modal open={state} onClose={close} title={`${plan.pdf}`}>
				<ModalContent>
					<object
						data={`${baseUrl}/fg_sync_applicable_otago_20/upload/${auth.user.dbname}/pdf_upload/${plan.pdf}`}
						type="application/pdf"
						width="100%"
						height="600"
						style={{ border: '1px solid #ddd' }}
					>
						<embed
							src={`${baseUrl}/fg_sync_applicable_otago_20/upload/${auth.user.dbname}/pdf_upload/${plan.pdf}`}
							type="application/pdf"
						/>
						{plan.pdf}
					</object>
					<div className="flex justify-end mt-4">
						<a
							href={`${baseUrl}/fg_sync_applicable_otago_20/upload/${auth.user.dbname}/pdf_upload/${plan.pdf}`}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-primary"
						>
							View in new tab
						</a>
					</div>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ManagePlans
