import React from 'react'
import swal from 'sweetalert'
import Modal, { ModalContent, ModalFooter } from 'components/Modal'
import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import FormFile from 'components/FormFile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { swalLoader } from 'utils/helpers'
import axios from 'axios'
import { saasUrl } from 'utils/api/config'
import useAuth from 'utils/Auth'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
	name: yup
		.string()
		.label('Name')
		.required(),
	level: yup
		.string()
		.label('Level')
		.required(),
	area: yup
		.string()
		.label('Area')
		.required(),
})

const initialValues = {
	name: '',
	level: '',
	area: '',
	pdf: null,
}

const AddArea = ({ isOpen, onClose, building, refetch }) => {
	const auth = useAuth()

	const handleSubmit = async (formData) => {
		if (!formData.pdf) {
			swal({
				text: 'PDF is a required field',
			})
			return
		}

		await swalLoader()

		try {
			const data = new FormData()

			data.append('name', formData.name)
			data.append('level', formData.level)
			data.append('area', formData.area)
			data.append('pdf', formData.pdf)
			data.append('buildingid', building.id)

			await axios.post(`${saasUrl}/api/create_area`, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `bearer ${auth.token}`,
				},
			})

			await refetch()
			onClose()

			await swal({
				icon: 'success',
				title: 'Area created',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				text: e.response.data.message,
			})
		}
	}

	return (
		<Modal open={isOpen} onClose={onClose} title="Add Area">
			<Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
				<ModalContent>
					<div className="form-group">
						<FormField name="name" label="Area Name" />
					</div>

					<div className="form-group">
						<FormField name="level" label="Level" />
					</div>

					<div className="form-group">
						<FormField name="area" label="Area" />
					</div>

					<div className="form-group">
						<FormFile name="pdf" label="PDF" />
					</div>
				</ModalContent>

				<ModalFooter>
					<Button theme="ghost" size="sm" className="mr-4" onClick={onClose} type="button">
						Cancel
					</Button>

					<Button theme="primary" type="submit">
						<FontAwesomeIcon icon={faCheck} className="mr-2" fixedWidth />
						Submit
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}

export default AddArea
