import React, { useRef } from 'react'
import Button from './Button'

const FileInput = ({ value, onChange = () => {}, multiple = false, className = '' }) => {
	const ref = useRef()

	const browse = (e) => {
		ref.current.click()
	}

	return (
		<div className={`input-file ${className}`} onClick={browse}>
			<input type="file" ref={ref} multiple={multiple} className="hidden" onChange={onChange} />

			<Button theme="dark" size="sm" className="pointer-events-none">
				Browse
			</Button>

			<div className="flex-1 ml-4 pointer-events-none">
				{!value ? 'No file chosen' : multiple ? value.map((file) => file.name).join(', ') : value.name}
			</div>
		</div>
	)
}

export default FileInput
