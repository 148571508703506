import React, { createContext, useContext, useReducer, useCallback } from 'react'

export const AuthContext = createContext()

export const Auth = {
	state: { token: null, user: null },
}

window.Auth = Auth

const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		(state, action) => {
			if (action.type === 'SET') return { ...state, ...action.payload }
			return state
		},
		localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : { token: null, user: null }
	)

	Auth.state = state

	const update = useCallback(
		(data) => {
			dispatch({ type: 'SET', payload: data })
			localStorage.setItem('auth', JSON.stringify(data))
		},
		[dispatch]
	)

	return <AuthContext.Provider value={{ ...state, update }}>{children}</AuthContext.Provider>
}

export default useAuth
