import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

const Search = ({ value, onChange, className = '', ...props }) => {
	const clear = () => {
		onChange('')
	}

	return (
		<div className={`relative ${className}`}>
			<input
				type="text"
				className="input pr-8"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				{...props}
			/>

			<div className="absolute right-0 top-0 h-full flex items-center mr-3">
				<FontAwesomeIcon
					icon={value ? faTimes : faSearch}
					className={value ? 'cursor-pointer opacity-100' : 'opacity-50'}
					fixedWidth
					onClick={clear}
				/>
			</div>
		</div>
	)
}

export default Search
