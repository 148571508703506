import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Select = ({ className = '', value, onChange, children, ...props }) => (
	<div className={`relative ${className}`} {...props}>
		<select className="input pr-5" value={value} onChange={onChange}>
			{children}
		</select>

		<div className="absolute h-full top-0 right-0 flex items-center mr-4 pointer-events-none">
			<FontAwesomeIcon icon={faChevronDown} />
		</div>
	</div>
)

export default Select
