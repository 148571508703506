import React from 'react'
import Page from 'components/Page'
import Button from 'components/Button'
import Section from 'components/Section'
import useQuery from 'hooks/useQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faKey, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'

import EditMasterUser from './EditMasterUser'
import ResetPassword from './ResetPassword'

const MasterAccount = () => {
	const { payload = {}, loading, query } = useQuery({
		endpoint: '/api/get_master_user',
	})

	return (
		<Page>
			<Section
				number={<FontAwesomeIcon icon={faUserCircle} size="sm" />}
				title="Master Account"
				description="Manage your master user account details and password."
				buttons={
					<>
						<ResetPassword user={payload} refetch={query}>
							{(open) => (
								<Button theme="primary-ghost" className="mr-4" onClick={open}>
									<FontAwesomeIcon icon={faKey} className="mr-2" fixedWidth />
									Reset Password
								</Button>
							)}
						</ResetPassword>

						<EditMasterUser user={payload} refetch={query}>
							{(open) => (
								<Button theme="primary-ghost" onClick={open}>
									<FontAwesomeIcon icon={faPen} className="mr-2" fixedWidth />
									Edit
								</Button>
							)}
						</EditMasterUser>
					</>
				}
			>
				{loading ? (
					<div className="flex justify-center mt-5">
						<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
					</div>
				) : (
					<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '1em' }}>
						<div className="flex flex-col flex-1 p-4 bg-blue-100 rounded-lg">
							<div className="font-bold text-blue-700 text-sm">Organisation Name</div>
							<div className="break-all text-2xl font-light">{payload.organisationname || '-'}</div>
						</div>
						<div className="flex flex-col flex-1 p-4 bg-blue-100 rounded-lg">
							<div className="font-bold text-blue-700 text-sm">Organisation Type</div>
							<div className="break-all text-2xl font-light">{payload.organisationtype || '-'}</div>
						</div>
						<div className="flex flex-col flex-1 p-4 bg-blue-100 rounded-lg">
							<div className="font-bold text-blue-700 text-sm">Contact Person Name</div>
							<div className="break-all text-2xl font-light">{payload.contactperson || '-'}</div>
						</div>
						<div className="flex flex-col flex-1 p-4 bg-blue-100 rounded-lg">
							<div className="font-bold text-blue-700 text-sm">Phone</div>
							<div className="break-all text-2xl font-light">{payload.phone || '-'}</div>
						</div>
						<div className="flex flex-col flex-1 p-4 bg-blue-100 rounded-lg">
							<div className="font-bold text-blue-700 text-sm">Mobile</div>
							<div className="break-all text-2xl font-light">{payload.mobile || '-'}</div>
						</div>
						<div className="flex flex-col flex-1 p-4 bg-blue-100 rounded-lg">
							<div className="font-bold text-blue-700 text-sm">Email</div>
							<div className="break-all text-2xl font-light">{payload.email || '-'}</div>
						</div>
					</div>
				)}

				{/* <div className="mb-8">
					<div className="font-bold text-blue-700 text-sm">Organisation Name</div>
					<div className="break-all">{payload.organisationname || '-'}</div>
				</div>

				<div className="mb-8">
					<div className="font-bold text-blue-700 text-sm">Organisation Type</div>
					<div className="break-all">{payload.organisationtype || '-'}</div>
				</div>

				<div className="mb-8">
					<div className="font-bold text-blue-700 text-sm">Contact Person Name</div>
					<div className="break-all">{payload.contactperson || '-'}</div>
				</div>

				<div className="mb-8">
					<div className="font-bold text-blue-700 text-sm">Phone</div>
					<div className="break-all">{payload.phone || '-'}</div>
				</div>

				<div className="mb-8">
					<div className="font-bold text-blue-700 text-sm">Mobile</div>
					<div className="break-all">{payload.mobile || '-'}</div>
				</div>

				<div className="">
					<div className="font-bold text-blue-700 text-sm">Email</div>
					<div className="break-all">{payload.email || '-'}</div>
				</div> */}
			</Section>
		</Page>
	)
}

export default MasterAccount
