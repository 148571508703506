import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import falconLogo from 'assets/img/falcon_grey_solutions-logo.png'
import victoriaLogo from 'assets/img/logoforvuw.png'
import otagoLogo from 'assets/img/otago_logo_saas.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import {
	faBuilding,
	faUser,
	faUserCircle,
	faChartBar,
	faFileAlt,
	faSun,
} from '@fortawesome/free-regular-svg-icons'
import useAuth from 'utils/Auth'
import { client } from 'utils/env'

const navProps = {
	className: 'nav-link',
	activeClassName: 'nav-link-active',
}

const logos = {
	victoria: victoriaLogo,
	falcon: falconLogo,
	otago: otagoLogo,
}

const Page = ({ children, history }) => {
	const auth = useAuth()

	const logout = () => {
		auth.update({
			token: null,
			user: null,
		})
	}

	const isAdmin = auth.user.type === 'Admin'
	const logo = logos[client]

	return (
		<div className="flex flex-row h-screen">
			<div className="bg-white w-64 ml-8 mt-8 mb-8 rounded-lg shadow-lg flex flex-col overflow-auto">
				<div className="flex flex-col flex-1">
					{/* <h1 className="text-2xl font-bold text-center text-gray-800 mb-8 mt-8">FalconGrey</h1> */}
					<div className="my-8 flex justify-center p-4">
						<img src={logo} />
					</div>

					<div className="flex flex-col justify-between flex-1">
						<div className="flex flex-col">
							<NavLink to="/" exact {...navProps}>
								<FontAwesomeIcon
									icon={faBuilding}
									className="mr-5"
									fixedWidth
								/>
								Buildings
							</NavLink>

							{isAdmin && (
								<NavLink to="/user-accounts" exact {...navProps}>
									<FontAwesomeIcon icon={faUser} className="mr-5" fixedWidth />
									User Accounts
								</NavLink>
							)}

							{isAdmin && (
								<NavLink to="/master-account" exact {...navProps}>
									<FontAwesomeIcon
										icon={faUserCircle}
										className="mr-5"
										fixedWidth
									/>
									Master Account
								</NavLink>
							)}

							<NavLink to="/service-details" exact {...navProps}>
								<FontAwesomeIcon
									icon={faChartBar}
									className="mr-5"
									fixedWidth
								/>
								Service Details
							</NavLink>

							{isAdmin && (
								<NavLink to="/billing-report" exact {...navProps}>
									<div
										className="mr-5 inline-flex items-center justify-center"
										style={{ width: '20px' }}
									>
										$
									</div>
									Billing Report
								</NavLink>
							)}

							{isAdmin && (
								<NavLink to="/installer-report" exact {...navProps}>
									<FontAwesomeIcon
										icon={faFileAlt}
										className="mr-5"
										fixedWidth
									/>
									Installer Report
								</NavLink>
							)}

							{isAdmin && (
								<NavLink to="/admin-options" exact {...navProps}>
									<FontAwesomeIcon icon={faSun} className="mr-5" fixedWidth />
									Admin Options
								</NavLink>
							)}
						</div>

						<button
							className="nav-link w-full rounded-bl-lg rounded-br-lg focus:outline-none"
							onClick={logout}
						>
							<div className="flex justify-between items-center">
								<div className="flex-1 text-left">
									{auth.user ? auth.user.username : ''}
								</div>
								<FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
							</div>
						</button>
					</div>
				</div>
			</div>

			<div className="p-8 flex-1 overflow-y-auto">{children}</div>
		</div>
	)
}

export default withRouter(Page)
