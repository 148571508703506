import React from 'react'
import { connect, getIn } from 'formik'
import FormError from './FormError'
import Select from './Select'

export const getClasses = (formik, name) => {
	return [
		getIn(formik.errors, name) &&
			getIn(formik.touched, name) &&
			'input-invalid',
	].join(' ')
}

const FormField = ({
	name,
	label = null,
	style = {},
	className = '',
	disabled,
	formik,
	...props
}) => {
	let value = getIn(formik.values, name)
	return (
		<div className={`flex-1 ${className}`} style={style}>
			{label ? (
				<label className="label" htmlFor={name}>
					{label}
				</label>
			) : null}
			<Select
				{...props}
				name={name}
				id={name}
				className={getClasses(formik, name)}
				value={value !== null && value !== undefined ? value : ''}
				onBlur={() => formik.setFieldTouched(name)}
				disabled={disabled}
				onChange={(e) => {
					formik.setFieldValue(name, e.target.value)
				}}
			/>
			<FormError name={name} />
		</div>
	)
}

export default connect(FormField)
