import React from 'react'

const Signpost = ({ number, title, description, className = '', ...props }) => {
	return (
		<div className={`flex items-start ${className}`} {...props}>
			<div className="inline-block rounded-full bg-blue-800 text-white text-3xl antialiased w-16 h-16 flex justify-center items-center mr-8">
				{number}
			</div>

			<div className="flex-1 flex flex-col">
				<div className="text-2xl font-semibold">{title}</div>
				<div className="text-sm text-blue-600">{description}</div>
			</div>
		</div>
	)
}

export default Signpost
