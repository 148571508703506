import React, { useState } from 'react'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faPen,
	faPlus,
	faMinus,
	faEllipsisV,
	faFileAlt,
	faFileMedical,
	faFileArchive,
	faFileExport,
	faSort,
	faSortUp,
	faSortDown,
	faFileInvoice,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import RightClicker from 'components/RightClicker'
import client from 'utils/api'
import { saasUrl } from 'utils/api/config'
import { swalLoader } from 'utils/helpers'
import useModalState from 'hooks/useModalState'
import useAuth from 'utils/Auth'

import AreaRow from './AreaRow'
import AddArea from './AddArea'
import EditBuilding from './EditBuilding'
import ViewReports from './ViewReports'

import axios from 'axios'
import FileSaver from 'file-saver'
import { format } from 'date-fns'

import useQuery from 'hooks/useQuery'

const BuildingRow = ({ building, refetch }) => {
	const auth = useAuth()
	const [open, setOpen] = useState(false)

	const [edit, openEdit, closeEdit] = useModalState()
	const [add, openAdd, closeAdd] = useModalState()
	const [reports, openReports, closeReports] = useModalState()

	const { payload: company_name = '' } = useQuery({
		endpoint: `/api/get_company_name`,
	})

	const { payload: custom_report_companies = [] } = useQuery({
		endpoint: `/api/get_custom_report_companies`,
	})

	const user_allowed_custom_report =
		custom_report_companies.includes(company_name)

	const exportZip = async () => {
		// window.open(`${saasUrl}/api/image_zip/${building.id}`)

		window.open(
			`${saasUrl}/auth/redirectTo?token=${auth.token}&path=/image_zip/download/${building.id}`
		)
	}

	const generateReport = async () => {
		await swalLoader('Generating Report')

		try {
			let { error, payload } = await client.query({
				endpoint: `/api_building_report/generateBuildingReport/${building.id}`,
			})

			if (error) throw payload

			await refetch()

			await swal({
				icon: 'success',
				title: 'Report generated',
			})
		} catch (e) {
			swal({
				icon: 'error',
				title: 'Report not generated',
				text: e.message,
			})
		}
	}

	const generateCustomReport = async () => {
		await swalLoader('Generating Report')

		try {
			let { error, payload } = await client.query({
				endpoint: `/custom_building_report/generateBuildingReport/${building.id}`,
			})

			if (error) throw payload

			await refetch()

			await swal({
				icon: 'success',
				title: 'Report generated',
			})
		} catch (e) {
			swal({
				icon: 'error',
				title: 'Report not generated',
				text: e.message,
			})
		}
	}

	const exportCSV = async () => {
		await swalLoader()

		try {
			const res = await axios.post(
				`${saasUrl}/api/create_building_data_csv`,
				{
					ids: building.id,
				},
				{
					headers: {
						Authorization: `bearer ${auth.token}`,
					},
				}
			)

			FileSaver.saveAs(
				new Blob([res.data]),
				`building-data-export-${format(new Date(), 'yyyy-mm-dd hh_mm_ss')}.csv`
			)

			swal.close()
		} catch (e) {
			console.log('error', e)
			swal({
				icon: 'error',
				title: 'Something went wrong',
			})
		}
	}

	const options = [
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faPlus} className="mr-3" fixedWidth />
					Add Area
				</div>
			),
			onClick: openAdd,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faPen} className="mr-3" fixedWidth />
					Edit Building
				</div>
			),
			onClick: openEdit,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faFileAlt} className="mr-3" fixedWidth />
					View Reports
				</div>
			),
			onClick: openReports,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faFileMedical} className="mr-3" fixedWidth />
					Generate Report
				</div>
			),
			onClick: generateReport,
		},
		user_allowed_custom_report && {
			label: (
				<div>
					<FontAwesomeIcon icon={faFileInvoice} className="mr-3" fixedWidth />
					Generate Custom Report
				</div>
			),
			onClick: generateCustomReport,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon
						icon={faFileExport}
						fixedWidth
						style={{ marginLeft: '0.15rem', marginRight: '0.60rem' }}
					/>
					Export Data
				</div>
			),
			onClick: exportCSV,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faFileArchive} className="mr-3" fixedWidth />
					Export All Photos
				</div>
			),
			onClick: exportZip,
		},
	]

	const [sort, setSort] = useState('name')
	const [dir, setDir] = useState('asc')

	const setSortCol = (name) => {
		if (sort === name) {
			if (dir === 'desc') {
				setSort('id')
				setDir('asc')
			} else {
				setDir('desc')
			}
		} else {
			setSort(name)
			setDir('asc')
		}
	}
	const sortedAreas = building.areas.sort((a, b) => {
		let high = dir === 'asc' ? 1 : -1
		let low = dir === 'asc' ? -1 : 1

		if (sort) {
			return String(a[sort]).toLowerCase() > String(b[sort]).toLowerCase()
				? high
				: low
		}

		return 0
	})

	const SortIcon = (field) => (
		<FontAwesomeIcon
			icon={sort === field ? (dir === 'asc' ? faSortUp : faSortDown) : faSort}
			className={`ml-2 ${sort === field ? 'text-blue-500' : 'text-gray-500'}`}
		/>
	)

	return (
		<>
			<RightClicker
				options={options}
				component="tr"
				className="cursor-pointer hover:bg-gray-100"
				onClick={() => setOpen((x) => !x)}
			>
				{(openMenu) => (
					<>
						<td>
							<Button theme={open ? 'primary' : 'primary-ghost'} size="sm">
								<FontAwesomeIcon icon={open ? faMinus : faPlus} fixedWidth />
							</Button>
						</td>
						<td>{building.designation}</td>
						<td>{building.consentnumber}</td>
						<td>{building.address1}</td>
						<td>
							<Button theme="ghost" onClick={openMenu}>
								<FontAwesomeIcon icon={faEllipsisV} />
							</Button>
						</td>
					</>
				)}
			</RightClicker>

			<EditBuilding
				isOpen={edit}
				onClose={closeEdit}
				building={building}
				refetch={refetch}
			/>
			<AddArea
				isOpen={add}
				onClose={closeAdd}
				building={building}
				refetch={refetch}
			/>
			<ViewReports
				isOpen={reports}
				onClose={closeReports}
				building={building}
			/>

			{open ? (
				<tr className="bg-gray-100">
					<td colSpan="5">
						<div className="p-6">
							<table className="table">
								<thead>
									<tr className="bg-white">
										<th
											className="cursor-pointer"
											onClick={() => setSortCol('name')}
										>
											Area Name {SortIcon('name')}
										</th>
										<th
											className="cursor-pointer"
											onClick={() => setSortCol('level')}
										>
											Level {SortIcon('level')}
										</th>
										<th
											className="cursor-pointer"
											onClick={() => setSortCol('area')}
										>
											Area {SortIcon('area')}
										</th>
										<th>PDF</th>
										<th>Current User</th>
										<th width="130">Force Release</th>
										<th width="50"></th>
									</tr>
								</thead>
								<tbody>
									{sortedAreas.map((area) => (
										<AreaRow key={area.id} area={area} refetch={refetch} />
									))}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			) : null}
		</>
	)
}

export default BuildingRow
