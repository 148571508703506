import React from 'react'
import { ClientContextProvider } from 'react-fetching-library'
import client from './utils/api'
import { AuthProvider } from './utils/Auth'
import 'animate.css'
import './assets/css/main.css'

import Router from './Router'

const App = () => {
	return (
		<AuthProvider>
			<ClientContextProvider client={client}>
				<Router />
			</ClientContextProvider>
		</AuthProvider>
	)
}

export default App
