import React, { useState, useEffect } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import DatePicker from 'components/DatePicker'
import Pagination from 'components/Pagination'
import TableInfo from 'components/TableInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faQuestionCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import useQuery from 'hooks/useQuery'
import Select from 'components/Select'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'

const ServiceDetails = () => {
	const [pageSize, setPageSize] = useState(10)
	const [page, setPage] = useState(1)
	const [from, setFrom] = useState(startOfMonth(new Date()))
	const [to, setTo] = useState(endOfMonth(new Date()))

	useEffect(() => {
		setPage(1)
	}, [from, to])

	const { payload: services = [], loading } = useQuery(
		{
			endpoint: `/api/services?from=${format(from, 'yyyy-MM-dd')}&to=${format(to, 'yyyy-MM-dd')}`,
		},
		[from, to]
	)

	const paginatedServices = pageSize === 'All' ? services : services.slice((page - 1) * pageSize, page * pageSize)

	return (
		<Page>
			<Section
				number={<FontAwesomeIcon icon={faChartBar} size="sm" />}
				title="Service Details"
				description="Summary of installation work carried out."
				buttons={
					<div className="flex">
						<div className="form-group w-64 mr-4">
							<label className="label">From</label>
							<DatePicker value={from} onChange={setFrom} />
						</div>

						<div className="form-group w-64">
							<label className="label">To</label>
							<DatePicker value={to} onChange={setTo} />
						</div>
					</div>
				}
			>
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<div className="mr-2">Per Page:</div>

						<Select className="w-20" value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={'All'}>All</option>
						</Select>
					</div>

					<Pagination
						page={page}
						onChange={setPage}
						totalPages={Math.ceil(services.length / pageSize) || 1}
					/>
				</div>

				{loading ? (
					<div className="flex justify-center mt-5">
						<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
					</div>
				) : (
					<table className="table">
						<thead>
							<tr>
								<th>Annotation ID</th>
								<th>Service Type</th>
								<th>System Used</th>
								<th>Installation Date</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{paginatedServices.map((service) => (
								<tr key={service.id}>
									<td>{service.id}</td>
									<td>{service.type}</td>
									<td>{service.system}</td>
									<td>{service.date}</td>
									<td>
										{service.status === 1 ? (
											<FontAwesomeIcon icon={faTimesCircle} className="text-red-600" />
										) : service.status === 2 ? (
											<FontAwesomeIcon icon={faQuestionCircle} className="text-orange-500" />
										) : (
											<FontAwesomeIcon icon={faCheckCircle} className="text-green-600" />
										)}
									</td>
								</tr>
							))}
							{services.length === 0 && (
								<tr>
									<td colSpan={5}>No services in this date range</td>
								</tr>
							)}
						</tbody>
					</table>
				)}

				<div className="flex items-center justify-between mb-3 mt-6">
					<TableInfo
						page={page}
						pageSize={pageSize}
						itemCount={paginatedServices.length}
						totalCount={services.length}
					/>

					<Pagination
						page={page}
						onChange={setPage}
						totalPages={Math.ceil(services.length / pageSize) || 1}
					/>
				</div>
			</Section>
		</Page>
	)
}

export default ServiceDetails
