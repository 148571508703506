import React, { useState, useRef, useMemo, useEffect } from 'react'
import {
	getDay,
	addDays,
	subDays,
	isSameDay,
	addMonths,
	subMonths,
	startOfMonth,
	endOfMonth,
	getDaysInMonth,
	isSameMonth,
	format,
} from 'date-fns'
import useOutsideClick from 'hooks/useOutsideClick'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const weekDays = 'MTWTFSS'.split('')

const DatePicker = ({ value, onChange, dateFormat = 'yyyy-MM-dd', ...props }) => {
	const [display, setDisplay] = useState('')
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (value && new Date(value).toString() !== 'Invalid Date') {
			setDisplay(format(new Date(value), dateFormat))
		}
	}, [value, dateFormat])

	const today = new Date()

	const [context, setContext] = useState(startOfMonth(today))

	const ref = useRef()
	useOutsideClick(ref, () => setOpen(false))

	const weeks = useMemo(() => {
		let daysInset = [...new Array(getDay(startOfMonth(context)) > 0 ? getDay(startOfMonth(context)) - 1 : 6)].map(
			(x, i, a) => subDays(endOfMonth(subMonths(context, 1)), a.length - (i + 1))
		)

		let daysInMonth = [...new Array(getDaysInMonth(context))].map((_, i) => addDays(startOfMonth(context), i))

		let days = [...daysInset, ...daysInMonth].reduce(
			(list, day) => {
				let lastList = list[list.length - 1]
				if (lastList.length === 7) return [...list, [day]]
				lastList.push(day)
				return list
			},
			[[]]
		)

		let daysOutset = [...new Array(7 - days[days.length - 1].length)].map((x, i) =>
			addDays(startOfMonth(addMonths(context, 1)), i)
		)

		days[days.length - 1] = days[days.length - 1].concat(daysOutset)

		return days
	}, [context])

	const selectDay = (day) => (e) => {
		e.preventDefault()
		e.stopPropagation()

		onChange(day)
		setOpen(false)
	}

	const show = () => {
		setOpen(true)
		setContext(value || today)
	}

	return (
		<div className="datepicker-wrapper" ref={ref}>
			<div className="relative">
				<input className="input pl-10" value={display} onChange={() => {}} onFocus={show} />
				<div className="absolute top-0 left-0 h-full flex items-center ml-3">
					<FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500" fixedWidth />
				</div>
			</div>

			{open && (
				<div className="datepicker-window">
					<div className="flex items-center mb-4 px-2">
						<Button onClick={() => setContext((x) => subMonths(x, 1))}>
							<FontAwesomeIcon icon={faChevronLeft} />
						</Button>
						<div className="flex-1 text-center">
							{format(context, 'MMM')} {format(context, 'yyyy')}
						</div>
						<Button onClick={() => setContext((x) => addMonths(x, 1))}>
							<FontAwesomeIcon icon={faChevronRight} />
						</Button>
					</div>

					<div className="flex border-b border-gray-200 pb-2 mb-2">
						{weekDays.map((d, i) => (
							<div key={i} className="datepicker-daycol">
								{d}
							</div>
						))}
					</div>

					<div className="flex flex-col">
						{weeks.map((week, i) => (
							<div key={i} className="flex">
								{week.map((day, j) =>
									!isSameMonth(day, context) ? (
										<div key={j} className="datepicker-fakeday">
											{format(day, 'd')}
										</div>
									) : (
										<div
											key={j}
											className={`datepicker-day ${
												isSameDay(day, today) ? 'datepicker-today' : ''
											} ${value && isSameDay(day, value) ? 'datepicker-selected' : ''}`}
											onClick={selectDay(day)}
										>
											{format(day, 'd')}
										</div>
									)
								)}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default DatePicker
