import React from 'react'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faUnlock, faFilePdf, faHistory, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import RightClicker from 'components/RightClicker'
import useModalState from 'hooks/useModalState'
import { saasUrl } from 'utils/api/config'
import client from 'utils/api'
import useAuth from 'utils/Auth'
import { swalLoader } from 'utils/helpers'

import EditArea from './EditArea'
import ManagePlans from './ManagePlans'

const AreaRow = ({ area, refetch }) => {
	const auth = useAuth()

	const [edit, openEdit, closeEdit] = useModalState()
	const [plans, openPlans, closePlans] = useModalState()

	const deleteArea = async () => {
		const confirmed = await swal({
			text: 'Are you sure you want to delete this area?',
			buttons: ['No', 'Yes'],
		})

		if (!confirmed) return

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				endpoint: `/api/delete_area/${area.id}`,
			})

			if (error) throw payload

			await refetch()

			await swal({
				icon: 'success',
				title: 'Area Deleted',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				message: e.message,
			})
		}
	}

	const unlockArea = async () => {
		const confirmed = await swal({
			text: 'Are you sure you want to force release this area?',
			buttons: ['No', 'Yes'],
		})

		if (!confirmed) return

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				endpoint: `/api/release_area/${area.id}`,
			})

			if (error) throw payload

			await refetch()

			await swal({
				icon: 'success',
				title: 'Area Released',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				message: e.message,
			})
		}
	}

	const options = [
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faFilePdf} className="mr-3" fixedWidth />
					Manage Plans
				</div>
			),
			onClick: openPlans,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faHistory} className="mr-3" fixedWidth />
					Annotation History
				</div>
			),
			component: 'a',
			href: `${saasUrl}/auth/redirectTo?token=${auth.token}&path=/annohistory?id=${area.id}`,
			// href: `${saasUrl}/annohistory?id=${area.id}`,
			target: '_blank',
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faPen} className="mr-3" fixedWidth />
					Edit Area
				</div>
			),
			onClick: openEdit,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faTrash} className="mr-3" fixedWidth />
					Delete Area
				</div>
			),
			onClick: deleteArea,
		},
	]

	return (
		<>
			<EditArea isOpen={edit} onClose={closeEdit} area={area} refetch={refetch} />
			<ManagePlans isOpen={plans} onClose={closePlans} area={area} refetch={refetch} />

			<RightClicker component="tr" className="hover:bg-gray-100" options={options}>
				{(openMenu) => (
					<>
						<td>{area.name}</td>
						<td>{area.level}</td>
						<td>{area.area}</td>
						<td>{area.plan.pdf}</td>
						<td>{area.current_user_email}</td>
						<td>
							{area.current_user_email ? (
								<Button theme="light" size="sm" onClick={unlockArea}>
									<FontAwesomeIcon icon={faUnlock} fixedWidth />
								</Button>
							) : null}
						</td>
						<td>
							<Button theme="ghost" onClick={openMenu}>
								<FontAwesomeIcon icon={faEllipsisV} />
							</Button>
						</td>
					</>
				)}
			</RightClicker>
		</>
	)
}

export default AreaRow
