import React, { useState } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import Checkbox from 'components/Checkbox'

const Test = () => {
	const [checked, setChecked] = useState(false)

	return (
		<Page>
			<Section>
				<h1 className="h1">checkbox</h1>

				<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
			</Section>
		</Page>
	)
}

export default Test
