import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Portal from './Portal'

const Modal = ({ open, onClose, title, size = '5xl', className = '', children }) => {
	const ref = useRef()

	const handleBackdropClick = (e) => {
		if (e.target === ref.current) {
			onClose()
		}
	}

	return open ? (
		<Portal>
			<div ref={ref} className="modal animated fadeIn fastest" onClick={handleBackdropClick}>
				<div className={`modal-window max-w-${size} animated zoomIn fastest ${className}`}>
					<div className="modal-header">
						<ModalTitle>{title}</ModalTitle>
						<button onClick={onClose} className="btn btn-none">
							<FontAwesomeIcon icon={faTimes} size="lg" />
						</button>
					</div>
					{children}
				</div>
			</div>
		</Portal>
	) : null
}

export const ModalTitle = (props) => <div className="modal-title" {...props} />
export const ModalContent = (props) => <div className="modal-content" {...props} />
export const ModalFooter = (props) => <div className="modal-footer" {...props} />

export default Modal
