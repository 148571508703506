import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import useAuth from './utils/Auth'

import Login from './pages/Login'
import Buildings from './pages/Buildings/Buildings'
import ServiceDetails from './pages/ServiceDetails/ServiceDetails'
import UserAccounts from './pages/UserAccounts/UserAccounts'
import MasterAccount from './pages/MasterAccount/MasterAccount'
import BillingReport from './pages/BillingReport/BillingReport'
import InstallerReport from './pages/InstallerReport/InstallerReport'
import AdminTools from './pages/AdminTools/AdminTools'
import Test from './pages/Test'

const routes = [
	{ path: '/login', Component: Login, authenticate: (_) => true },
	{ path: '/', Component: Buildings, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/service-details', Component: ServiceDetails, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/user-accounts', Component: UserAccounts, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/master-account', Component: MasterAccount, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/billing-report', Component: BillingReport, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/installer-report', Component: InstallerReport, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/admin-options', Component: AdminTools, authenticate: (auth) => auth.token, redirectTo: '/login' },
	{ path: '/test', Component: Test, authenticate: (auth) => auth.token, redirectTo: '/login' },
]

const Router = () => {
	const auth = useAuth()

	return (
		<BrowserRouter>
			<Switch>
				{routes.map(({ path, Component, authenticate, redirectTo }) => (
					<Route
						key={path}
						path={path}
						exact
						render={(router) => {
							if (authenticate(auth)) {
								return <Component {...router} />
							}
							return (
								<Redirect
									to={{ pathname: redirectTo, state: { referrer: router.location.pathname } }}
								/>
							)
						}}
					/>
				))}
			</Switch>
		</BrowserRouter>
	)
}

export default Router
