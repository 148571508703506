import { useState, useMemo, useEffect, useRef } from 'react'
import { useClient, useQuery } from 'react-fetching-library'

const useQueryImproved = (action, params = []) => {
	// get the cache for react-fetching-library
	const { cache } = useClient()

	// memoize the query action so that it isn't computed every render
	const queryAction = useMemo(() => action, params)

	// create a piece of state, where the default state is either the value from cache, or a loading state
	// console.log('data', cache.getItems()[queryAction.endpoint])
	const [data, setData] = useState({ ...cache.getItems()[queryAction.endpoint], loading: true } || { loading: true })

	// go ahead and run the request to the server
	const res = useQuery(queryAction)

	// when we get a response from the server request
	useEffect(() => {
		// set that to the data state
		if (res.payload) setData(res)
	}, [res.payload, res.loading])

	const ref = useRef()

	useEffect(() => {
		if (!queryAction.force) return

		if (ref.current) {
			let hasChange = ref.current.reduce((acc, _, i) => {
				if (ref.current[i] !== params[i]) return true
				return acc
			}, false)

			if (hasChange) {
				res.query()
			}
		}

		ref.current = params
	}, [...params, queryAction, res])

	return data
}

export default useQueryImproved
