import React, { useState } from 'react'
import swal from 'sweetalert'
import decode from 'jwt-decode'
import axios from 'axios'
import Section from 'components/Section'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import useAuth from 'utils/Auth'
import { saasUrl } from 'utils/api/config'

const Login = ({ history, location }) => {
	const auth = useAuth()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()

		// auth.update({ token: 'yo' })
		try {
			let res = await axios.post(saasUrl + '/auth/login', {
				email,
				password,
			})

			console.log('res', res)

			const user = decode(res.data.token).user

			if (user.type === 'Tablet only') {
				swal({
					icon: 'error',
					title: 'User is set to tablet only',
				})
				return
			}

			auth.update({ token: res.data.token, user: user })

			const locationState = location.state || {}
			history.push(locationState.referrer || '/')
		} catch (e) {
			console.dir(e)
			swal({
				icon: 'error',
				text: e.response.data.error,
			})
		}
	}

	return (
		<div className="h-screen w-screen flex items-center justify-center px-4">
			<Section style={{ maxWidth: '700px', width: '100%' }}>
				<h1 className="text-2xl font-bold mb-8">FireReport Admin Login</h1>

				<form onSubmit={handleSubmit}>
					<div className="flex-1 mb-4">
						<label className="label">Email Address</label>
						<input className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
					</div>

					<div className="flex-1 mb-4">
						<label className="label">Password</label>
						<input
							className="input"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>

					<div className="flex justify-end mt-8">
						<Button theme="primary" type="submit">
							<FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
							Sign In
						</Button>
					</div>
				</form>
			</Section>
		</div>
	)
}

export default Login
