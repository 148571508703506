import React from 'react'
import Modal, { ModalContent } from 'components/Modal'
import { saasUrl } from 'utils/api/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { format } from 'date-fns'
import useAuth from 'utils/Auth'

const ViewReports = ({ isOpen, onClose, building }) => {
	const auth = useAuth()

	return (
		<Modal open={isOpen} onClose={onClose} title={`Reports: ${building.designation}`}>
			<ModalContent>
				<table className="table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Created By</th>
							<th>Type</th>
							<th>View Report</th>
						</tr>
					</thead>
					<tbody>
						{building.reports.map((report, i) => (
							<tr key={report.id}>
								<td>{format(new Date(report.timestamp), 'yyyy-MM-dd')}</td>
								<td></td>
								<td>Passive Fire</td>
								<td>
									<a
										href={
											`${saasUrl}/auth/redirectTo?token=${auth.token}&path=/welcome/reportShowHTML?` +
											`building=${encodeURIComponent(encodeURIComponent(building.designation))}&day=${report.timestamp}&rs=${report.randomstring}&db_name=fr`.replace(
												/&/g,
												'%26'
											)
										}
										target="_blank"
										rel="noopener noreferrer"
										title="PDF (new window)"
									>
										<FontAwesomeIcon icon={faFilePdf} className="text-red-500 mr-2" />
										Report {i + 1}
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</ModalContent>
		</Modal>
	)
}

export default ViewReports
