import React from 'react'
import { Field, getIn } from 'formik'

const ErrorMessage = ({ name, className = '' }) => {
	return (
		<div className={`text-red-500 italic text-sm mt-1 ${className}`}>
			<Field
				name={name}
				render={({ form }) => {
					const error = getIn(form.errors, name)
					const touch = getIn(form.touched, name)
					return touch && error ? error : null
				}}
			/>
		</div>
	)
}

export default ErrorMessage
