import React, { useState } from 'react'
import swal from 'sweetalert'
import axios from 'axios'
import FileSaver from 'file-saver'
import Page from 'components/Page'
import Button from 'components/Button'
import Section from 'components/Section'
import DatePicker from 'components/DatePicker'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFileExport, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'
import useQuery from 'hooks/useQuery'
import client from 'utils/api'
import { saasUrl } from 'utils/api/config'
import { swalLoader } from 'utils/helpers'
import { startOfMonth, endOfMonth, format } from 'date-fns'

const InstallerReport = () => {
	const [installations, setInstallations] = useState(null)

	const { payload: autocoms = { installers: [] }, loading } = useQuery({
		endpoint: '/api/get_autocoms',
	})

	const [from, setFrom] = useState(startOfMonth(new Date()))
	const [to, setTo] = useState(endOfMonth(new Date()))
	const [installer, setInstaller] = useState(0)

	const generate = async () => {
		if (!installer) return false

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: '/api/get_installer_report',
				body: {
					from,
					to,
					installer: installer,
				},
			})

			if (error) throw payload

			setInstallations(payload)
			setSelected(payload.map((x) => x.id))
			swal.close()
		} catch (e) {
			console.log('error', e)
			swal({
				icon: 'error',
				title: 'Something went wrong',
			})
		}
	}

	const [selected, setSelected] = useState([])
	const select = (anno) => {
		setSelected((s) => (s.indexOf(anno.id) > -1 ? s.filter((x) => x !== anno.id) : [...s, anno.id]))
	}
	const selectAll = () => {
		if (selected.length === installations.length) {
			setSelected([])
		} else {
			setSelected(installations.map((x) => x.id))
		}
	}

	const exportCSV = async () => {
		if (!selected.length) return

		await swalLoader()

		try {
			const res = await axios.post(
				`${saasUrl}/api/create_billing_report_csv`,
				{
					ids: selected,
				},
				{
					headers: {
						Authorization: 'bearer ' + localStorage.getItem('token'),
					},
				}
			)

			FileSaver.saveAs(
				new Blob([res.data]),
				`installer-activity-export-${format(new Date(), 'yyyy-mm-dd hh_mm_ss')}.csv`
			)

			swal.close()
		} catch (e) {
			console.log('error', e)
			swal({
				icon: 'error',
				title: 'Something went wrong',
			})
		}
	}

	return (
		<Page>
			<Section
				number={<FontAwesomeIcon icon={faFileAlt} size="sm" />}
				title="Installer Report"
				description="Please select appropriate filters and run report. Export will be in CSV format, suitable for opening in Excel."
			>
				{loading ? (
					<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
				) : (
					<div className="flex items-end">
						<div className="flex-1 mr-4">
							<label className="label">Installer</label>
							<Select value={installer} onChange={(e) => setInstaller(e.target.value)}>
								<option></option>
								{autocoms.installers.map((name, i) => (
									<option key={i} value={name}>
										{name}
									</option>
								))}
							</Select>
						</div>

						<div className="flex-1 mr-4">
							<label className="label">From</label>
							<DatePicker value={from} onChange={setFrom} />
						</div>

						<div className="flex-1 mr-4">
							<label className="label">To</label>
							<DatePicker value={to} onChange={setTo} />
						</div>

						<div>
							<Button theme="primary" onClick={generate}>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" fixedWidth />
								Generate
							</Button>
						</div>
					</div>
				)}

				{installations && (
					<>
						<table className="table mt-16">
							<thead>
								<tr className="bg-gray-100">
									<th width="46">
										<Checkbox
											checked={selected.length === installations.length}
											indeterminate={
												selected.length > 0 && selected.length < installations.length
											}
											onChange={selectAll}
										/>
									</th>
									<th>Annotation ID</th>
									<th>Service Type</th>
									<th>System Used</th>
									<th>Installation Date</th>
									<th>Multiservice</th>
									<th>Invoiced</th>
								</tr>
							</thead>
							<tbody>
								{installations.map((anno) => (
									<tr
										key={anno.id}
										className={`cursor-pointer ${
											selected.indexOf(anno.id) > -1
												? 'bg-blue-100 hover:bg-blue-200'
												: 'hover:bg-blue-100'
										}`}
										onClick={() => select(anno)}
									>
										<td>
											<Checkbox
												checked={selected.indexOf(anno.id) > -1}
												onChange={() => select(anno)}
											/>
										</td>
										<td>{anno.id}</td>
										<td>{anno.type}</td>
										<td>{anno.system}</td>
										<td>{format(new Date(anno.date), 'yyyy-MM-dd')}</td>
										<td>{anno.multiservice ? 'Multiservice' : null}</td>
										<td>{anno.invoicedornot ? 'Invoiced' : 'Uninvoiced'}</td>
									</tr>
								))}
							</tbody>
						</table>

						<div className="flex justify-end mt-4">
							<Button theme="primary" onClick={exportCSV}>
								<FontAwesomeIcon icon={faFileExport} fixedWidth className="mr-2" />
								Export ({selected.length})
							</Button>
						</div>
					</>
				)}
			</Section>
		</Page>
	)
}

export default InstallerReport
