import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Pagination = ({ page = 1, totalPages = 1, className = '', onChange, loading = false, ...props }) => {
	const next = () => onChange(page + 1)
	const prev = () => onChange(page - 1)

	const pages = useMemo(() => new Array(totalPages).fill(undefined).reduce((x, _, i) => [...x, i + 1], []))

	return (
		<div className={`flex items-center ${className}`} {...props}>
			<div className="mr-2 flex items-center">
				{loading && <FontAwesomeIcon icon={faCircleNotch} spin className="mr-2" />}
				<div className="mr-2">Page</div>
				<select className="input" onChange={(e) => onChange(e.target.value)} value={page}>
					{pages.map((x) => (
						<option key={x}>{x}</option>
					))}
				</select>
			</div>

			<button
				onClick={prev}
				disabled={page === 1}
				className="px-3 py-1 rounded-tl rounded-bl bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-500"
			>
				<FontAwesomeIcon icon={faChevronLeft} />
			</button>

			<button
				onClick={next}
				disabled={page === totalPages}
				className="px-3 py-1 rounded-tr rounded-br bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-500"
			>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
		</div>
	)
}

export default Pagination
