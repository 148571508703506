import React, { useState } from 'react'
import Page from 'components/Page'
import Button from 'components/Button'
import Section from 'components/Section'
import Search from 'components/Search'
import useModalState from 'hooks/useModalState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import { faUser } from '@fortawesome/free-regular-svg-icons'
import useQuery from 'hooks/useQuery'

import AddUser from './AddUser'
import UserRow from './UserRow'

const compareStr = (a, b) => {
	if (!a || !b) return false
	return a.toLowerCase().indexOf(b.toLowerCase()) !== -1
}

const UserAccounts = () => {
	const [add, openAdd, closeAdd] = useModalState()

	const [filter, setFilter] = useState('')
	const [sort, setSort] = useState('designation')
	const [dir, setDir] = useState('asc')

	const setSortCol = (name) => {
		if (sort === name) {
			if (dir === 'desc') {
				setSort(null)
				setDir('asc')
			} else {
				setDir('desc')
			}
		} else {
			setSort(name)
			setDir('asc')
		}
	}

	const { payload: users = [], loading, query } = useQuery({
		endpoint: `/api/users`,
	})

	const filteredUsers = users
		.filter((x) => {
			if (filter === '') return true
			if (compareStr(x.username, filter)) return true
			if (compareStr(x.email, filter)) return true
			if (compareStr(x.type, filter)) return true

			return false
		})
		.sort((a, b) => {
			let high = dir === 'asc' ? 1 : -1
			let low = dir === 'asc' ? -1 : 1

			if (sort) {
				return `${a[sort]}`.toLowerCase() > `${b[sort]}`.toLowerCase() ? high : low
			}

			return 0
		})

	const SortIcon = (field) => (
		<FontAwesomeIcon
			icon={sort === field ? (dir === 'asc' ? faSortUp : faSortDown) : faSort}
			className={`ml-2 ${sort === field ? 'text-blue-500' : 'text-gray-500'}`}
		/>
	)

	return (
		<Page>
			<AddUser isOpen={add} onClose={closeAdd} refetch={query} />

			<Section
				number={<FontAwesomeIcon icon={faUser} size="sm" />}
				title="User accounts"
				description="Manage your add-on user accounts and passwords."
				buttons={
					<>
						<Search
							placeholder="Search users..."
							value={filter}
							onChange={setFilter}
							className="mr-4 w-64"
						/>

						<Button theme="primary-ghost" onClick={openAdd}>
							<FontAwesomeIcon icon={faPlus} className="mr-2" fixedWidth />
							Add User
						</Button>
					</>
				}
			>
				{loading ? (
					<div className="flex justify-center mt-5">
						<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
					</div>
				) : (
					<table className="table table-hover">
						<thead>
							<tr className="bg-gray-100">
								<th className="cursor-pointer" onClick={() => setSortCol('username')}>
									Name {SortIcon('username')}
								</th>
								<th className="cursor-pointer" onClick={() => setSortCol('email')}>
									Account Email {SortIcon('email')}
								</th>
								<th className="cursor-pointer" onClick={() => setSortCol('type')}>
									User Type {SortIcon('type')}
								</th>
								<th className="cursor-pointer" onClick={() => setSortCol('tabletaccess')}>
									Tablet Access {SortIcon('tabletaccess')}
								</th>
								<th width="60"></th>
							</tr>
						</thead>

						<tbody>
							{filteredUsers.map((user, i) => (
								<UserRow key={user.id} user={user} refetch={query} />
							))}
						</tbody>
					</table>
				)}
			</Section>
		</Page>
	)
}

export default UserAccounts
