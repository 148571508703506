import React, { useState } from 'react'
import Checkbox from 'components/Checkbox'
import { format } from 'date-fns'

const BillingRow = ({ area, select, selected }) => {
	const [open, setOpen] = useState(true)

	return (
		<>
			<tr className="bg-gray-100">
				<td>
					{/* <Button theme={open ? 'primary' : 'primary-ghost'} size="sm">
						<FontAwesomeIcon icon={open ? faMinus : faPlus} fixedWidth />
					</Button> */}
				</td>
				<td
					colSpan={5}
					onClick={() => setOpen((x) => !x)}
					className="text-sm font-bold"
				>
					<div className="flex items-center justify-between">
						<div>Area: {area.name}</div>
						<div>Sub-total: {area.annotations.length}</div>
					</div>
				</td>
			</tr>
			{open &&
				area.annotations.map((anno) => (
					<tr
						key={anno.id}
						className={`cursor-pointer ${
							selected.indexOf(anno.id) > -1
								? 'bg-blue-100 hover:bg-blue-200'
								: 'hover:bg-blue-100'
						}`}
						onClick={() => select(anno)}
					>
						<td>
							<Checkbox
								checked={selected.indexOf(anno.id) > -1}
								onChange={() => select(anno)}
							/>
						</td>
						<td>{anno.id}</td>
						<td>{anno.type}</td>
						<td>{anno.system}</td>
						<td>
							{anno.date === null
								? ''
								: format(new Date(anno.date), 'yyyy-MM-dd')}
						</td>
						<td>{anno.invoicedornot ? 'Invoiced' : 'Uninvoiced'}</td>
					</tr>
				))}
		</>
	)
}

export default BillingRow
