import React from 'react'
import { Formik } from 'formik'

const Form = ({ children, id, debug = false, className = '', style = {}, ...props }) => {
	return (
		<Formik {...props}>
			{(formik) => {
				debug && console.log('Form debug', formik)
				return (
					<form
						onSubmit={(e) => {
							e.stopPropagation()
							formik.handleSubmit(e)
						}}
						id={id}
						autoComplete="off"
						className={className}
						style={style}
					>
						{typeof children === 'function' ? children(formik) : children}
					</form>
				)
			}}
		</Formik>
	)
}

export default Form
