import React from 'react'
import swal from '@sweetalert/with-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

// await this function if there's a chance that nothing async may happen before you run swal.close()
// it's stupid, I know. But SweetAlert won't recognise it if you try run swal.close() too fast
export const swalLoader = (text) => {
	swal({
		content: (
			<div className="text-center">
				<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
				{text ? <div className="mt-4 text-bold text-lg">{text}</div> : null}
			</div>
		),
		buttons: false,
		closeOnClickOutside: false,
	})
	return new Promise((resolve) => setTimeout(resolve, 100))
}
