import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

const Checkbox = ({ checked, onChange, indeterminate, className = '', ...props }) => (
	<label className={`cursor-pointer ${className} inline-block`}>
		<input
			type="checkbox"
			checked={checked}
			onChange={onChange}
			className="hidden"
			ref={(el) => el && (el.indeterminate = indeterminate)}
		/>
		<FontAwesomeIcon
			icon={indeterminate ? faMinusSquare : checked ? faCheckSquare : faSquare}
			className={checked || indeterminate ? 'opacity-100' : 'opacity-50'}
		/>
	</label>
)

export default Checkbox
