import React, { useState, useRef, useEffect } from 'react'
import useOutsideClick from 'hooks/useOutsideClick'
import Portal from './Portal'

const RightClicker = ({ options, enabled = true, children, className = '', component = 'div', ...props }) => {
	const ref = useRef()
	const [open, setOpen] = useState(null)
	const Comp = component

	const handleClick = (e) => {
		if (e.type === 'contextmenu' && !e.ctrlKey) {
			e.preventDefault()

			if (enabled) {
				setOpen({
					x: e.nativeEvent.pageX,
					y: e.nativeEvent.pageY,
				})
			}
		}
	}

	const triggerClick = (e) => {
		e.stopPropagation()
		e.preventDefault()

		if (enabled) {
			setOpen({
				x: e.nativeEvent.pageX,
				y: e.nativeEvent.pageY,
			})
		}
	}

	const handleClickWith = (fn) => (e) => {
		e.stopPropagation()
		fn(e)
		setOpen(false)
	}

	useOutsideClick(ref, () => setOpen(null))

	const [portal] = useState(document.createElement('div'))

	useEffect(() => {
		portal.className = 'rc-portal'

		if (open) {
			document.body.appendChild(portal)
		} else {
			try {
				document.body.removeChild(portal)
			} catch (e) {}
		}

		return () => {
			try {
				document.body.removeChild(portal)
			} catch (e) {}
		}
	}, [open, portal])

	const position = { top: open ? open.y + 'px' : '' }

	if (open) {
		if (open.x + 200 < window.innerWidth) {
			position.left = open.x + 'px'
		} else {
			position.right = window.innerWidth - open.x
		}
	}

	return (
		<Comp className={`relative ${className}`} onContextMenu={handleClick} {...props}>
			{typeof children === 'function' ? children(triggerClick) : children}
			{open && (
				<Portal>
					<div
						ref={ref}
						className={`bg-blue-800 text-white antialiased flex flex-col absolute z-10 overflow-hidden ${
							position.left
								? 'rounded-tr-lg rounded-br-lg rounded-bl-lg'
								: 'rounded-tl-lg rounded-br-lg rounded-bl-lg'
						}`}
						style={{ ...position }}
					>
						{options
							.filter((x) => x)
							.map(({ label, component: Comp = 'button', ...optProps }, i) =>
								Comp === 'button' ? (
									<Comp
										key={i}
										type="button"
										{...optProps}
										className="px-3 py-2 border-b border-blue-700 text-left text-sm focus:outline-none hover:bg-blue-700 focus:bg-blue-900"
										onClick={handleClickWith(optProps.onClick)}
									>
										{label}
									</Comp>
								) : (
									<Comp
										key={i}
										className="px-3 py-2 border-b border-blue-700 text-left text-sm focus:outline-none hover:bg-blue-700 focus:bg-blue-900"
										{...optProps}
									>
										{label}
									</Comp>
								)
							)}
					</div>
				</Portal>
			)}
		</Comp>
	)
}

export default RightClicker
