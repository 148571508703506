import React from 'react'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faEllipsisV, faTrash, faKey } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import RightClicker from 'components/RightClicker'
import useModalState from 'hooks/useModalState'
import { swalLoader } from 'utils/helpers'
import client from 'utils/api'

import EditUser from './EditUser'
import ResetPassword from './ResetPassword'

const UserRow = ({ user, refetch }) => {
	const [edit, openEdit, closeEdit] = useModalState()
	const [reset, openReset, closeReset] = useModalState()

	const deleteUser = async () => {
		const confirmed = await swal({
			text: 'Are you sure you want to delete this user?',
			buttons: ['No', 'Yes'],
		})

		if (!confirmed) return

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				endpoint: `/api/delete_user/${user.id}`,
			})

			if (error) throw payload

			await refetch()

			await swal({
				icon: 'success',
				title: 'User Deleted',
			})
		} catch (e) {
			console.log(e)

			swal({
				icon: 'error',
				message: e.message,
			})
		}
	}

	const options = [
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faKey} className="mr-3" fixedWidth />
					Reset Password
				</div>
			),
			onClick: openReset,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faPen} className="mr-3" fixedWidth />
					Edit User
				</div>
			),
			onClick: openEdit,
		},
		{
			label: (
				<div>
					<FontAwesomeIcon icon={faTrash} className="mr-3" fixedWidth />
					Delete User
				</div>
			),
			onClick: deleteUser,
		},
	]

	return (
		<>
			<RightClicker component="tr" options={options}>
				{(openMenu) => (
					<>
						<td>{decodeURI(user.username)}</td>
						<td>{user.email}</td>
						<td>{user.type}</td>
						<td>{user.tabletaccess ? 'Yes' : 'No'}</td>
						<td>
							<Button theme="ghost" onClick={openMenu}>
								<FontAwesomeIcon icon={faEllipsisV} />
							</Button>
						</td>
					</>
				)}
			</RightClicker>

			<EditUser isOpen={edit} onClose={closeEdit} user={user} refetch={refetch} />
			<ResetPassword isOpen={reset} onClose={closeReset} user={user} refetch={refetch} />
		</>
	)
}

export default UserRow
