import React, { useState } from 'react'
import swal from 'sweetalert'
import axios from 'axios'
import FileSaver from 'file-saver'
import Page from 'components/Page'
import Button from 'components/Button'
import Section from 'components/Section'
import DatePicker from 'components/DatePicker'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faClipboardCheck, faFileExport, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import useQuery from 'hooks/useQuery'
import client from 'utils/api'
import { saasUrl } from 'utils/api/config'
import { swalLoader } from 'utils/helpers'
import { startOfMonth, endOfMonth, format } from 'date-fns'
import useAuth from 'utils/Auth'

import BillingRow from './BillingRow'

const BillingReport = () => {
	const auth = useAuth()
	const [billing, setBilling] = useState(null)

	const { payload: buildings = [], loading } = useQuery({
		endpoint: '/api/buildings',
	})

	const [from, setFrom] = useState(startOfMonth(new Date()))
	const [to, setTo] = useState(endOfMonth(new Date()))
	const [invoiced, setInvoiced] = useState(0)
	const [building, setBuilding] = useState('')

	const generate = async () => {
		if (!building) return false

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: '/api/get_billing_report',
				body: {
					from,
					to,
					building_id: building,
					invoicedornot: invoiced,
				},
			})

			if (error) throw payload

			setBilling(payload)
			setSelected(payload.flatMap((x) => x.annotations).map((x) => x.id))
			swal.close()
		} catch (e) {
			console.log('error', e)
			swal({
				icon: 'error',
				title: 'Something went wrong',
			})
		}
	}

	const [selected, setSelected] = useState([])
	const select = (anno) => {
		setSelected((s) => (s.indexOf(anno.id) > -1 ? s.filter((x) => x !== anno.id) : [...s, anno.id]))
	}
	const selectAll = () => {
		if (selected.length === billing.flatMap((x) => x.annotations).length) {
			setSelected([])
		} else {
			setSelected(billing.flatMap((x) => x.annotations).map((x) => x.id))
		}
	}

	const markAsInvoiced = async () => {
		if (!selected.length) return

		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: '/api/set_invoiced',
				body: {
					ids: selected,
				},
			})

			if (error) throw payload

			await generate()

			swal({
				icon: 'success',
				title: 'Annotations marked as invoiced',
			})
		} catch (e) {
			console.log('error', e)
			swal({
				icon: 'error',
				title: 'Something went wrong',
			})
		}
	}

	const exportCSV = async () => {
		if (!selected.length) return

		await swalLoader()

		try {
			const res = await axios.post(
				`${saasUrl}/api/create_billing_report_csv`,
				{
					ids: selected,
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `bearer ${auth.token}`,
					},
				}
			)

			FileSaver.saveAs(new Blob([res.data]), `data_export_${format(new Date(), 'yyyy-mm-dd hh_mm_ss')}.csv`)

			swal.close()
		} catch (e) {
			console.log('error', e)
			swal({
				icon: 'error',
				title: 'Something went wrong',
			})
		}
	}

	return (
		<Page>
			<Section
				number="$"
				title="Billing Report"
				description="Please select appropriate filters, run report, deselect any items NOT being billed at this time and then 'Export and mark as billed'. Export will be in CSV format, suitable for opening in Excel."
			>
				{loading ? (
					<FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
				) : (
					<div className="flex items-end">
						<div className="flex-1 mr-4">
							<label className="label">Building</label>
							<Select value={building} onChange={(e) => setBuilding(e.target.value)}>
								<option></option>
								{buildings.map((building, i) => (
									<option key={i} value={building.id}>
										{building.designation}
									</option>
								))}
							</Select>
						</div>

						<div className="flex-1 mr-4">
							<label className="label">From</label>
							<DatePicker value={from} onChange={setFrom} />
						</div>

						<div className="flex-1 mr-4">
							<label className="label">To</label>
							<DatePicker value={to} onChange={setTo} />
						</div>

						<div className="flex-1 mr-4">
							<label className="label">Status</label>
							<Select value={invoiced} onChange={(e) => setInvoiced(e.target.value)}>
								<option value={0}>Uninvoiced</option>
								<option value={1}>Invoiced</option>
								<option value={'all'}>All</option>
							</Select>
						</div>

						<div>
							<Button theme="primary" onClick={generate}>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" fixedWidth />
								Generate
							</Button>
						</div>
					</div>
				)}

				{billing ? (
					<>
						<hr className="mt-16" />
						<table className="table table-fixed mt-16">
							<thead>
								<tr className="bg-gray-100">
									<th width="46">
										<Checkbox
											checked={selected.length === billing.flatMap((x) => x.annotations).length}
											indeterminate={
												selected.length > 0 &&
												selected.length < billing.flatMap((x) => x.annotations).length
											}
											onChange={selectAll}
										/>
									</th>
									<th>Annotation ID</th>
									<th>Service Type</th>
									<th>System Used</th>
									<th>Installation Date</th>
									<th>Invoiced</th>
								</tr>
							</thead>
							<tbody>
								{billing.map((area) => (
									<BillingRow key={area.id} area={area} selected={selected} select={select} />
								))}
								{billing.length === 0 && (
									<tr>
										<td colSpan={6}>No annotations match your filter criteria</td>
									</tr>
								)}
							</tbody>
						</table>

						<div className="flex justify-end mt-4">
							<Button theme="primary-ghost" className="mr-4" onClick={markAsInvoiced}>
								<FontAwesomeIcon icon={faClipboardCheck} fixedWidth className="mr-2" />
								Mark selected as invoiced
							</Button>
							<Button theme="primary" onClick={exportCSV}>
								<FontAwesomeIcon icon={faFileExport} fixedWidth className="mr-2" />
								Export ({selected.length})
							</Button>
						</div>
					</>
				) : null}
			</Section>
		</Page>
	)
}

export default BillingReport
