import React from 'react'
import swal from 'sweetalert'
import Modal, { ModalContent, ModalFooter } from 'components/Modal'
import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import * as yup from 'yup'
import Form from 'components/Form'
import FormField from 'components/FormField'
import FormSelect from 'components/FormSelect'
import client from 'utils/api'
import { swalLoader } from 'utils/helpers'

const validationSchema = yup.object().shape({
	username: yup.string().label('Name').required(),
	email: yup.string().email().label('Email Address').required(),
})

const EditUser = ({ isOpen, onClose, user, refetch }) => {
	const initialValues = {
		username: user.username || '',
		email: user.email || '',
		type: user.type || '',
		tabletaccess:
			user.tabletaccess !== undefined && user.tabletaccess !== null
				? user.tabletaccess
				: 1,
	}

	const handleSubmit = async (formData) => {
		await swalLoader()

		try {
			const { error, payload } = await client.query({
				method: 'POST',
				endpoint: `/api/update_user/${user.id}`,
				body: formData,
			})

			if (error) throw payload

			await refetch()
			onClose()

			await swal({
				icon: 'success',
				title: 'User updated',
			})
		} catch (e) {
			swal({
				icon: 'error',
				text: e.message,
			})
		}
	}

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			title={`Edit User: ${user.username}`}
		>
			<Form
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				<ModalContent>
					<div className="form-group">
						<FormField name="username" label="Name" />
					</div>

					<div className="form-group">
						<FormField name="email" label="Email Address" />
					</div>

					<div className="form-group">
						<FormSelect name="type" label="User Type">
							<option value="Admin">Admin</option>
							<option value="Staff">Staff</option>
							<option value="Tablet only">Tablet only</option>
						</FormSelect>
					</div>

					<div className="form-group">
						<FormSelect name="tabletaccess" label="Tablet Access">
							<option value={1}>Yes</option>
							<option value={0}>No</option>
						</FormSelect>
					</div>
				</ModalContent>

				<ModalFooter>
					<Button
						theme="ghost"
						size="sm"
						className="mr-4"
						onClick={onClose}
						type="button"
					>
						Cancel
					</Button>

					<Button theme="primary" type="submit">
						<FontAwesomeIcon icon={faCheck} className="mr-2" fixedWidth />
						Submit
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}

export default EditUser
